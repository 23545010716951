.register-page {
    display: flex;
    justify-content: center;
    margin: 0px auto 20px;

    .card {
        width: 100%;
        padding: 20px 40px 30px 40px;
        max-width: 840px;
    }

    .main-heading {
        text-align: center;
        margin: 20px 0px 40px 0px;
    }


    // ---


    .MuiFormControl-root {
        display: flex;
    }

    .section-heading {
        margin-bottom: 70px;
    }

    .form-group {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;

        &.btm-1 {
            margin-bottom: 1rem;
        }
    }

    .MuiInput-underline:after {
        border-bottom: none;
    }

    .MuiInput-underline:before {
        // border-bottom: 1px solid #ccc !important;
    }

    .captcha-center p {
        text-align: left;
    }

    .form-lbl {

        color: #000;
        text-transform: uppercase;
        // font-weight: bold;
        letter-spacing: 0.00938em;
        position: relative;

        .mandate, > span {
            color: #db3131 !important;
            top: 0;
            margin-left: 4px;
        }
    }

    .google-captch-wrap {

        .btn {
            min-width: 24px;
            padding: 0;
            height: 24px;
            border-radius: 80px;
            position: relative;
            left: 15px;
        }

        input {
            height: 50px;
            margin-top: -10px;
            outline: 0;

            &:focus {
                outline: 0;
                box-shadow: none;
            }
        }
    }

    .MuiFormLabel-root.Mui-error {
        color: #000;
    }

    .MuiFormHelperText-root.Mui-error,
    .input-error {
        // font-size: 12px;
        color: #db3327;
        margin-top: 5px;
        text-align: left;
    }

}