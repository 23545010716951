$bgcolor: #545b62;
$textcolor: #000;
$fontsize: 18px;
$headingFontSize: 25px;
@import "register";
@import "resetpassword";
@import "profile";
@import "earnpoint";
@import "validatepurchase";
@import "rewards";
@import "tierinfo";
// @import "variables";
// @import "colors";
// @import "reset";

.card {
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	transition: 0.3s;
	margin-bottom: 20px;
	&:hover {
		box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
	}
	.cart-button {
		color: #fff;
		background-color: $bgcolor;
		border-color: $bgcolor;
		margin: 10px 20px;
	}
}

.submit button {
	color: #fff;
	background-color: #545b62;
	border-color: #545b62;
	margin: 10px 20px;
}
/* portal point list */
.portal-point-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 10px 0px;
	.title {
		margin: 10px 30px 0 30px;
	}
	.points-actions {
		margin: 10px 30px 0 30px;
	}
}

.social-login {
	display: flex;
	justify-content: center;
	& > div {
		margin: 10px;
	}
}
/*footer */
footer {
	padding-top: 30px;
	padding-bottom: 20px;
}
.footer-container {
	display: flex;
	justify-content: center;
}
.social-links {
	display: flex;
	justify-content: center;
	margin-top: 20px;

 ul {
	margin-bottom: 0;
	margin-left: 0;
}
li { padding-left: 0;
display: inline;}

}

.footer-social {
	list-style: none;
	display: inline;
	padding: 0px;
	li {
		float: left;
		padding: 1rem;
	}
}

.footer-link a,
.footer-link button {
	text-decoration: none;
	transition-duration: 0.5s;
}
.footer-link button {
	padding: 0;
	border: 0;
	margin: 0;
	outline: 0;
	box-shadow: none !important;
}
.footer-link a:hover,
.footer-link a:focus,
.footer-link button:focus,
.footer-link button:hover {
	text-decoration: underline;
	outline: 0;
	border: 0;
}

.footer-link {
	display: flex;
	justify-content: center;
	ul {
		list-style: none;
		display: inline;
		padding-left: 0;
		margin-bottom: 0;
		text-align: center;
	}
	li {
		padding: 0 10px;
		display: inline-block;
		line-height: 20px;
	}
}

.copy-text {
	margin: 2rem 0 20px 0px;
	text-align: center;
	font-size: 1.125rem;
	p {
		display: inline;
	}
}
.tierlogic {
	margin: 20px 0px 0;
	text-align: center;
}
.tierlogic img {
	height: 28px;
}

.loaderHolder {
	left: 0;
	top: 0;
	position: fixed;
	background: transparent;
	width: 100%;
	height: 100%;
	background-color: rgba(126, 125, 111, 0.23);
	z-index: 1100;
}
.loaderMain {
	position: absolute;
	left: 50%;
	top: 35%;
	z-index: 1000;
	height: 31px;
	width: 31px;
}
.kep-login-facebook.metro {
	font-size: 13px;
	width: 200px;
	padding: 10px 0px;
}
#confirm-account-h1 {
	border: 1px solid #ccc;
	padding: 40px 40px;
	color: #4ab116;
	text-align: center;
}
#confirm-account-h1 {
	border: 1px solid #ccc;
	padding: 40px 40px;
	color: #4ab116;
	text-align: center;
}
.react-calender {
	position: absolute;
}

// Medium devices (tablets, less than 992px)
@media (min-width: 991.98px) {
	.top-navigation {
		justify-content: center;
	}
}
.menu-notification-icon {
	color: rgb(157, 160, 11);
	text-decoration: none;
	position: relative;
	display: inline-block;
	border-radius: 2px;
}

.menu-notification-icon:hover {
	color: red;
}

.menu-notification-icon .badge {
	position: absolute;
	top: -10px;
	right: -10px;
	padding: 5px 8px;
	border-radius: 50%;
	background: red;
	color: white;
}
.notification-sidepanel {
	width: 35%;
	position: fixed;
	z-index: 77;
	min-height: 80%;
	max-height: 80%;
	top: 100px;
	right: 0;
	background-color: #eeebeb;
	overflow-x: hidden;
	transition: 0.5s;
	padding-top: 20px;
}
.notification-sidepanel .closebtn {
	float: right;
}
.not-read-notificaion {
	color: #d60000;
}
.otp-input {
	width: 40px;
	height: 40px;
}
