
body {
	font-size: 1.125rem;
	line-height: 1.5rem;
	font-family: 'Oswald', sans-serif;
	color: #000;
}

h1 {
	font-size: 2rem;
	padding: 20px 0 10px;
	font-weight: 400;
}
h2 {
	font-size: 1.375rem;
	padding: 16px 0 10px;
	text-transform: uppercase;
	font-weight: 400;
}
h3 {
	font-size: 1rem;
	padding: 10px 0 6px;
	font-weight: 400;
}
.modal{top:10% !important}


.skiptocontent{position: absolute;
opacity: 1;
left: 0%;
top:-60px;
padding:3px 10px;
z-index: 9999;
background: #000; color: #fff;
/*  transition: -webkit-transform .166s;
    transition: transform .166s;
    transition: transform .166s,-webkit-transform .166s;
    -webkit-transform: translate(-50%,-110%);
    -ms-transform: translate(-50%,-110%);
    transform: translate(-50%,-110%); */
}
.skiptocontent:focus{opacity: 1;
	top:0px;
	background: #000; color: #fff;
}

.btn-primary,
.submit .btn {
	font-size: 1.125rem;
	font-weight: 400;
	color: #fff;
	background-color: #C42026;
	text-transform: uppercase;
	border: none;
	border-radius: 0;
	min-width: 200px;
	padding: 12px 30px;
	margin: 10px 0px;
	transition-duration: 0.5s;
	text-decoration: none;
}

.btn-primary:hover,
.submit .btn:hover {
	background-color: #A11216;
}

.btn-primary:focus,
.submit .btn:focus {
	background-color: #A11216;
}

.btn-primary:disabled,
.submit .btn:disabled {
	background-color: #979797;
}

.btn-link,
a {
	color: #000;
	font-size: 1.125rem;
	font-weight: 400;
	text-decoration: underline;
}
.btn-link:hover,
a:hover,
a:focus {
	color: #000;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
	background-color: #000;
	border: none;
}



.navbar {
	padding-top: 0;
	padding-bottom: 0;
	background-color: #fff;
	height: 70px;
	box-shadow: 0px 3px 15px 0px rgba(54, 54, 54, 0.6);
	-webkit-box-shadow: 0px 3px 15px 0px rgba(54, 54, 54, 0.6);
	-moz-box-shadow: 0px 3px 15px 0px rgba(54, 54, 54, 0.6);
}

.navbar-brand img {
	max-height: 50px;
}

.navbar-light .navbar-nav .nav-link {
	color: #000;
	background-color: #fff;
	text-decoration: none;
}
.navbar-light .navbar-nav .nav-item .nav-link {
	padding: 5px 16px 5px !important;
	margin-left: 2px;
	margin-right: 2px;
	font-weight: 400;
	font-size: 1rem;
	transition-duration: 0.5s;
	border-radius: 0px;
	text-transform: uppercase;
	text-decoration: none;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
	background: none;
	color:#000;
}
li:focus-within {
	background: none;
}

footer {
	background-color: #00884A;
	color: #fff;
}
footer a,
.footer-link a,
.footer-link button {
	color: #fff;
	font-size: 1.125rem;
	word-break: break-all;
}
footer a:hover,
footer a:focus,
.footer-link a:hover,
.footer-link a:focus,
.footer-link button:focus,
.footer-link button:hover {
	color: #fff;
}

.adchoices{height: 18px;margin-left: 4px; margin-top: -3px;}

.footer-offer {
	padding: 20px 0 20px;
	line-height: 24px;
	font-size: 1.125rem;
	text-align: center;
}
.social-links a {
	color: #fff;
	font-size: 20px;
}
.social-links a:hover {
	color: #fff;
}
.carousel-indicators {
	display: none;
	opacity: 0;
}
.section-content {
	padding: 50px 0;
}

.form-control::placeholder,
::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder,
::placeholder,
input:-moz-placeholder {
	color: #6e6e6e;
}

.show-placeholder.MuiInputBase-input::placeholder,
.show-placeholder.form-control::placeholder,
.show-placeholder input::placeholder {
	color: #000 !important;
	opacity: 0.7 !important;
}

.register-page .card {
	max-width: 900px;
	box-shadow: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	border: none;
}

.card,
.card:focus,
.card:hover {
	box-shadow: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	border: none;
}

.register-page .forgot-pass-links {
	text-align: inherit;
	margin: 10px 0 30px;
}

.MuiFormHelperText-root.Mui-error,
.input-error {
	font-size: 1rem;
	font-family: 'Oswald', sans-serif;
}

.register-page .form-group {
	margin-bottom: 25px;
	margin-top: 25px;
}

.textfield{width: 100%;}
.MuiInputBase-input,
.form-control {
	font-size: 1.125rem;
}
.MuiInputLabel-formControl {
	position: static;
	/* transform: initial !important; */
}
.MuiInputLabel-shrink {
	transform: initial !important;
}
.MuiInputBase-input.Mui-disabled{color:#404040}


.MuiInputBase-input::placeholder,
.form-control::placeholder {
	font-size: 1rem;
}
.MuiFormLabel-root.Mui-focused {
	color: #000 !important;
}

.MuiFormControl-root label,
.input_label,
.col-form-label {
	font-size: 1.125rem;
	color: #000;
	text-transform: uppercase;
}

.google-captch-wrap label{padding-top:0;padding-bottom:0;}
.support_page .captcha-left p {
    text-align: left;

}
.support_page .MuiInputLabel-formControl {
	position: absolute !important;
    /* transform: translate(0, 24px) scale(1); */
}
.support_page .MuiFormLabel-root:after{
    content: "*";
    color: #db3131;
    position: relative;
    left: 2px;
}

.support_page .MuiFormLabel-root.Mui-error{color:rgb(0, 0, 0)}

.support_page .MuiInputBase-multiline{padding: 6px 0 0px;}
.MuiInputLabel-root{font-family: 'Oswald', sans-serif !important;}
.MuiInputBase-root .MuiInputBase-input,
.form-control {
	height: 1.6em;
	margin-top: 6px;
	padding-bottom: 3px;
	padding-top: 10px;
	border: none;
	border-radius: 0;
	border-bottom: 4px solid #000;
	font-size: 1.125rem;
}

.form-control {
	padding: 16px 0 17px !important;
	border-bottom: 4px solid #000;
	color: #000 !important;
}
.form-control:focus {
	border-color: #000;
	box-shadow: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
}

.MuiSelect-select.MuiSelect-select {
	padding-left: 15px;
}

.MuiSelect-select.MuiSelect-select:focus,
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
	background-color: none !important;
}

input:-internal-autofill-selected {
	background-color: #fff !important;
}


@media (min-width: 576px) {
	.mobile-hide {
		display: none !important;
	}
	
}

.faq .accordion > .card > .card-header,
.faq .accordion .card-header {
	background-color: #000;
	color: #FFF;
	font-size: 1.125rem;
	line-height: 32px;
}
.faq .accordion .card-body {
	padding: 20px 30px;
	line-height: 34px;
}
.accordion .card-body li {
	padding: 5px 0;
}
.faq_open-close-icon{ color: #fff;
	line-height: 2rem;
	font-size: 2rem;
	font-family: "Helvetica Neue","Arial";
	font-weight: 100;
}

.validate-purchase-div table thead {
	background-color: #000;
	color: #fff;
}

@media (min-width: 576px) {
	.mobile-hide {
		display: none !important;
	}
}

@media (max-width: 575.98px) {
	p {
		text-align: center;
	}
	.faq .accordion .card-body{padding: 20px 15px;line-height: 26px;}
	.faq .accordion > .card > .card-header, .faq .accordion .card-header{line-height: 26px;}
	.desktop-hide {
		display: none !important;
	}
	.validate-purchase-div{padding: 5% 0;}
	.validate-purchase-div .btn-primary{min-width: 150px;}

	.top-left {
		padding-left: 16px;
	}
	.top-left img {
		width: auto;
		height: 40px;
	}
	.navbar-nav {
		background-color: #fff;
		padding-bottom: 20px;
	}

	.navbar-light .navbar-nav .nav-item .nav-link {
		margin-top: 5px;
		margin-bottom: 5px;
		padding-top: 8px;
		padding-bottom: 8px;
		line-height: 40px;
		font-size: 18px;
	}

	.navbar {
		padding: 8px 0;
	}
	.navbar .navbar-toggler {
		margin-right: 16px;
	}

	.navbar-nav {
		box-shadow: 0px 11px 12px 0px rgba(54, 54, 54, 0.3);
		-webkit-box-shadow: 0px 11px 12px 0px rgba(54, 54, 54, 0.3);
		-moz-box-shadow: 0px 11px 12px 0px rgba(54, 54, 54, 0.3);
	}

	.carouselCustom {
		margin-bottom: 40px;
	}

	.section-content {
		padding-top: 10px;
	}
	.resetpassword-page {
		margin-top: 40px;
	}
	.register-page {
		margin-top: 0px;
	}

	.register-page .section-heading {
		margin-bottom: 30px;
	}

	.register-page .card {
		padding: 20px 0px 30px 0px;
		width: 94%;
	}

	.resetpassword-page .card {
		padding: 20px 0px 30px 0px;
	}

	.accordion > .card > .card-header {
		padding: 12px 0;
	}

	.btn-primary,
	.submit .btn,
	.register-page .submit button {
		padding-left: 10px;
		padding-right: 10px;
	}

	.footer-link ul li {
		line-height: 40px;
	}
}

.ele-mandate {
	color: #db3131 !important;
	position: relative;
	top: 0;
	right: -3px;
}

@media screen and (max-width: 992px) and (min-width: 576px) {
	.navbar-collapse .show {
		position: absolute !important;
		top: 70px !important;
	}
	.navbar-collapse {
		position: absolute;
		top: 70px;
		width: 100%;
		min-height: 280px;
		left: 0;
	}
	.navbar-light .navbar-text {
		text-align: left;
		left: 0;
	}
	.navbar-light .navbar-nav .nav-item .nav-link {
		border-radius: 0px !important;
	}
}

.pagination-block {
	display: flex;
	align-items: center;
}
.pagination-block .pagination.react-bootstrap-table-page-btns-ul {
	float: none;
}
.pagination > li > a,
.page-link{
	text-decoration: none !important;
}
.page-item.active .page-link{
	background-color: #0367d3;
		border-color: #0367d3;
}
.home-product-heading{
	font-size: 22px;
	text-align: center;
}
.home-product-list{
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
}
.home-product-list li{
	padding: 4px 0;
	margin: 0;
}